import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './application-user.reducer';

export const ApplicationUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const applicationUserEntity = useAppSelector(state => state.applicationUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="applicationUserDetailsHeading">
          <Translate contentKey="activitiesApp.applicationUser.detail.title">ApplicationUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{applicationUserEntity.id}</dd>
          <dt>
            <span id="additionalField">
              <Translate contentKey="activitiesApp.applicationUser.additionalField">Additional Field</Translate>
            </span>
          </dt>
          <dd>{applicationUserEntity.additionalField}</dd>
          <dt>
            <span id="profileImage">
              <Translate contentKey="activitiesApp.applicationUser.profileImage">Profile Image</Translate>
            </span>
          </dt>
          <dd>
            {applicationUserEntity.profileImage ? (
              <div>
                {applicationUserEntity.profileImageContentType ? (
                  <a onClick={openFile(applicationUserEntity.profileImageContentType, applicationUserEntity.profileImage)}>
                    <img
                      src={`data:${applicationUserEntity.profileImageContentType};base64,${applicationUserEntity.profileImage}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {applicationUserEntity.profileImageContentType}, {byteSize(applicationUserEntity.profileImage)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.applicationUser.internalUser">Internal User</Translate>
          </dt>
          <dd>{applicationUserEntity.internalUser ? applicationUserEntity.internalUser.id : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.applicationUser.activity">Activity</Translate>
          </dt>
          <dd>
            {applicationUserEntity.activities
              ? applicationUserEntity.activities.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {applicationUserEntity.activities && i === applicationUserEntity.activities.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.applicationUser.activityDetails">Activity Details</Translate>
          </dt>
          <dd>
            {applicationUserEntity.activityDetails
              ? applicationUserEntity.activityDetails.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {applicationUserEntity.activityDetails && i === applicationUserEntity.activityDetails.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/application-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/application-user/${applicationUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApplicationUserDetail;
