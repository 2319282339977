import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { IApplicationUser } from 'app/shared/model/application-user.model';
import { IActivityDetailsPrice } from './activity-details-price.model';

export interface IActivityDetails {
  id?: number;
  name?: string | null;
  activityType?: keyof typeof ActivityType | null;
  active?: boolean | null;
  quote?: string | null;
  summaryDescription?: string | null;
  detailedDescription?: string | null;
  startDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  activityImageOneContentType?: string | null;
  activityImageOne?: string | null;
  activityImageTwoContentType?: string | null;
  activityImageTwo?: string | null;
  activityImageThreeContentType?: string | null;
  activityImageThree?: string | null;
  activityImageFourContentType?: string | null;
  activityImageFour?: string | null;
  activityImageFiveContentType?: string | null;
  activityImageFive?: string | null;
  whatsIncluded?: string | null;
  whatToBring?: string | null;
  departs?: string | null;
  restrictions?: string | null;
  duration?: string | null;
  vendorLocation?: string | null;
  childFriendly?: boolean | null;
  childFriendlyNote?: string | null;
  mediaLinks?: string | null;
    activityId?: number | null;
    timeBaseAvailability?: boolean | null;
  location?: ILocation | null;
  applicationUsers?: IApplicationUser[] | null;
  activityDetailsPrices?:IActivityDetailsPrice[]
}

export const defaultValue: Readonly<IActivityDetails> = {
  active: false,
  childFriendly: false,
  timeBaseAvailability: false,
};
