import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityDetailsDateTimeAvailability from './activity-details-date-time-availability';
import ActivityDetailsDateTimeAvailabilityDetail from './activity-details-date-time-availability-detail';
import ActivityDetailsDateTimeAvailabilityUpdate from './activity-details-date-time-availability-update';
import ActivityDetailsDateTimeAvailabilityDeleteDialog from './activity-details-date-time-availability-delete-dialog';

const ActivityDetailsDateTimeAvailabilityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityDetailsDateTimeAvailability />} />
    <Route path="new" element={<ActivityDetailsDateTimeAvailabilityUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityDetailsDateTimeAvailabilityDetail />} />
      <Route path="edit" element={<ActivityDetailsDateTimeAvailabilityUpdate />} />
      <Route path="delete" element={<ActivityDetailsDateTimeAvailabilityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityDetailsDateTimeAvailabilityRoutes;
