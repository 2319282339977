import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { TextFormat, Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
//import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './activity-details-date-time-availability.reducer';

export const ActivityDetailsDateTimeAvailability = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overridePaginationStateWithQueryParams(getSortState(pageLocation, parseInt('id')), pageLocation.search));

  const activityDetailsDateTimeAvailabilityList = useAppSelector(state => state.activityDetailsDateTimeAvailability.entities);
  const loading = useAppSelector(state => state.activityDetailsDateTimeAvailability.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="activity-details-date-time-availability-heading" data-cy="ActivityDetailsDateTimeAvailabilityHeading">
        <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.home.title">
          Activity Details Date Time Availabilities
        </Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/activity-details-date-time-availability/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.home.createLabel">
              Create new Activity Details Date Time Availability
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {activityDetailsDateTimeAvailabilityList && activityDetailsDateTimeAvailabilityList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('fromDate')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.fromDate">From Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('fromDate')} />
                </th>
                <th className="hand" onClick={sort('toDate')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.toDate">To Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('toDate')} />
                </th>
                <th className="hand" onClick={sort('hour')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.hour">Hour</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hour')} />
                </th>
                <th className="hand" onClick={sort('minute')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.minute">Minute</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('minute')} />
                </th>
                <th className="hand" onClick={sort('ampm')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.ampm">Ampm</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('ampm')} />
                </th>
                <th className="hand" onClick={sort('adultCount')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.adultCount">Adult Count</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('adultCount')} />
                </th>
                <th className="hand" onClick={sort('userName')}>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.userName">User Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('userName')} />
                </th>
                <th>
                  <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.activity">Activity</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activityDetailsDateTimeAvailabilityList.map((activityDetailsDateTimeAvailability, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/activity-details-date-time-availability/${activityDetailsDateTimeAvailability.id}`}
                      color="link"
                      size="sm"
                    >
                      {activityDetailsDateTimeAvailability.id}
                    </Button>
                  </td>
                  <td>
                    {activityDetailsDateTimeAvailability.fromDate ? (
                      <TextFormat type="date" value={activityDetailsDateTimeAvailability.fromDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {activityDetailsDateTimeAvailability.toDate ? (
                      <TextFormat type="date" value={activityDetailsDateTimeAvailability.toDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    <Translate contentKey={`activitiesApp.Hour.${activityDetailsDateTimeAvailability.hour}`} />
                  </td>
                  <td>
                    <Translate contentKey={`activitiesApp.Minute.${activityDetailsDateTimeAvailability.minute}`} />
                  </td>
                  <td>
                    <Translate contentKey={`activitiesApp.AMPM.${activityDetailsDateTimeAvailability.ampm}`} />
                  </td>
                  <td>{activityDetailsDateTimeAvailability.adultCount}</td>
                  <td>{activityDetailsDateTimeAvailability.userName}</td>
                  <td>
                    {activityDetailsDateTimeAvailability.activity ? (
                      <Link to={`/activity-details/${activityDetailsDateTimeAvailability.activity.id}`}>
                        {activityDetailsDateTimeAvailability.activity.name}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/activity-details-date-time-availability/${activityDetailsDateTimeAvailability.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-details-date-time-availability/${activityDetailsDateTimeAvailability.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/activity-details-date-time-availability/${activityDetailsDateTimeAvailability.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.home.notFound">
                No Activity Details Date Time Availabilities found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ActivityDetailsDateTimeAvailability;
