import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Activity from './activity';
import ActivityImage from './activity-image';
import ActivityHighlight from './activity-highlight';
import ActivityIncludedItem from './activity-included-item';
import ApplicationUser from './application-user';
import ActivityNote from './activity-note';
import ActivityAvailability from './activity-availability';
import Country from './country';
import Location from './location';
import City from './city';
import ActivityPrice from './activity-price';
import Booking from './booking';
import ActivityReview from './activity-review';
import BookingPament from './booking-pament';
import BookingUser from './booking-user';
import BookingPax from './booking-pax';
import ActivityTimeAvailability from './activity-time-availability';
import BookingTime from './booking-time';
import WeekDayTime from './week-day-time';
import WeekDayTimeAvailability from './week-day-time-availability';
import ActivitySchedule from './activity-schedule';
import Deal from './deal';
import Itinerary from './itinerary';
import Emailing from './emailing';
import ContentImages from './content-images';
import SiteSettings from './site-settings';
import CriticalLogging from './critical-logging/critical-logging';
import ActivityFaq from './activity-faq';
import Keyword from './keyword';
import DiningDetails from './dining-details';
import MealType from './meal-type';
import Cusine from './cusine';
import ActivityDetails from './activity-details';
import ActivityDetailsPrice from './activity-details-price';
import ActivityDetailsDateTimeAvailability from './activity-details-date-time-availability';

/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="activity/*" element={<Activity />} />
        <Route path="activity-image/*" element={<ActivityImage />} />
        <Route path="activity-highlight/*" element={<ActivityHighlight />} />
        <Route path="activity-included-item/*" element={<ActivityIncludedItem />} />
        <Route path="application-user/*" element={<ApplicationUser />} />
        <Route path="activity-note/*" element={<ActivityNote />} />
        <Route path="activity-availability/*" element={<ActivityAvailability />} />
        <Route path="country/*" element={<Country />} />
        <Route path="location/*" element={<Location />} />
        <Route path="city/*" element={<City />} />
        <Route path="activity-price/*" element={<ActivityPrice />} />
        <Route path="booking/*" element={<Booking />} />
        <Route path="activity-review/*" element={<ActivityReview />} />
        <Route path="booking-pament/*" element={<BookingPament />} />
        <Route path="booking-user/*" element={<BookingUser />} />
        <Route path="booking-pax/*" element={<BookingPax />} />
        <Route path="activity-time-availability/*" element={<ActivityTimeAvailability />} />
        <Route path="booking-time/*" element={<BookingTime />} />
        <Route path="week-day-time/*" element={<WeekDayTime />} />
        <Route path="week-day-time-availability/*" element={<WeekDayTimeAvailability />} />
        <Route path="activity-schedule/*" element={<ActivitySchedule />} />
        <Route path="deal/*" element={<Deal />} />
        <Route path="itinerary/*" element={<Itinerary />} />
        <Route path="emailing/*" element={<Emailing />} />
        <Route path="content-images/*" element={<ContentImages />} />
        <Route path="critical-logging/*" element={<CriticalLogging />} />
        <Route path="site-settings/*" element={<SiteSettings />} />
        <Route path="activity-faq/*" element={<ActivityFaq />} />
        <Route path="keyword/*" element={<Keyword />} />
        <Route path="dining-details/*" element={<DiningDetails />} />
        <Route path="meal-type/*" element={<MealType />} />
        <Route path="cusine/*" element={<Cusine />} />
        <Route path="activity-details/*" element={<ActivityDetails />} />
        <Route path="activity-details-price/*" element={<ActivityDetailsPrice />} />
        <Route path="activity-details-date-time-availability/*" element={<ActivityDetailsDateTimeAvailability />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
