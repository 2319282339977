import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getActivityDetails } from 'app/entities/activity-details/activity-details.reducer';
import { createEntity, getEntity, reset, updateEntity } from './activity-details-price.reducer';

export const ActivityDetailsPriceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activityDetails = useAppSelector(state => state.activityDetails.entities);
  const activityDetailsPriceEntity = useAppSelector(state => state.activityDetailsPrice.entity);
  const loading = useAppSelector(state => state.activityDetailsPrice.loading);
  const updating = useAppSelector(state => state.activityDetailsPrice.updating);
  const updateSuccess = useAppSelector(state => state.activityDetailsPrice.updateSuccess);

  const handleClose = () => {
    navigate('/activity-details-price');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getActivityDetails({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.fromPax !== undefined && typeof values.fromPax !== 'number') {
      values.fromPax = Number(values.fromPax);
    }
    if (values.toPax !== undefined && typeof values.toPax !== 'number') {
      values.toPax = Number(values.toPax);
    }
    if (values.price !== undefined && typeof values.price !== 'number') {
      values.price = Number(values.price);
    }

    const entity = {
      ...activityDetailsPriceEntity,
      ...values,
      activity: activityDetails.find(it => it.id.toString() === values.activity?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...activityDetailsPriceEntity,
          activity: activityDetailsPriceEntity?.activity?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityDetailsPrice.home.createOrEditLabel" data-cy="ActivityDetailsPriceCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityDetailsPrice.home.createOrEditLabel">
              Create or edit a ActivityDetailsPrice
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-details-price-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityDetailsPrice.fromPax')}
                id="activity-details-price-fromPax"
                name="fromPax"
                data-cy="fromPax"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetailsPrice.toPax')}
                id="activity-details-price-toPax"
                name="toPax"
                data-cy="toPax"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetailsPrice.price')}
                id="activity-details-price-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                id="activity-details-price-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityDetailsPrice.activity')}
                type="select"
              >
                <option value="" key="0" />
                {activityDetails
                  ? activityDetails.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-details-price" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityDetailsPriceUpdate;
