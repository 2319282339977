import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedBlobField, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapIdList } from 'app/shared/util/entity-utils';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getLocations } from 'app/entities/location/location.reducer';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { createEntity, getEntity, reset, updateEntity } from './activity-details.reducer';
import { getEntities as getApplicationUsers } from 'app/entities/application-user/application-user.reducer';
import { IActivityDetailsPrice } from '../../shared/model/activity-details-price.model';

export const ActivityDetailsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const locations = useAppSelector(state => state.location.entities);
  const applicationUsers = useAppSelector(state => state.applicationUser.entities);
  const activityDetailsEntity = useAppSelector(state => state.activityDetails.entity);
  const loading = useAppSelector(state => state.activityDetails.loading);
  const updating = useAppSelector(state => state.activityDetails.updating);
  const updateSuccess = useAppSelector(state => state.activityDetails.updateSuccess);
  const activityTypeValues = Object.keys(ActivityType);

  console.log('-----++++++++++++++++++++++++++++++++++++++++++++++++++++' + JSON.stringify(activityDetailsEntity.activityDetailsPrices) )
  const [activityDetailsPrices, setActivityDetailsPrices] = useState<IActivityDetailsPrice[]>(activityDetailsEntity.activityDetailsPrices || []);

  const handleClose = () => {
    navigate('/activity-details');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getLocations({}));
    dispatch(getApplicationUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setActivityDetailsPrices(activityDetailsEntity.activityDetailsPrices);
  }, [activityDetailsEntity.activityDetailsPrices]);



  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    if (values.activityId !== undefined && typeof values.activityId !== 'number') {
      values.activityId = Number(values.activityId);
    }

    const entity = {
      ...activityDetailsEntity,
      ...values,
      location: locations.find(it => it.id.toString() === values.location?.toString()),
      applicationUsers: mapIdList(values.applicationUsers),
      activityDetailsPrices: activityDetailsPrices
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
        }
      : {
          activityType: 'ADVENTURE',
          ...activityDetailsEntity,
          startDate: convertDateTimeFromServer(activityDetailsEntity.startDate),
          endDate: convertDateTimeFromServer(activityDetailsEntity.endDate),
          location: activityDetailsEntity?.location?.id,
          applicationUsers: activityDetailsEntity?.applicationUsers?.map(e => e.id.toString()),
          activityDetailsPrices: activityDetailsEntity?.activityDetailsPrices
        };

        const handleAddPrice = () => {
          setActivityDetailsPrices([...activityDetailsPrices, {  price: 0, fromPax: 1, toPax:1 }]);
        };

        const handleEditPrice = (index: number, field: keyof IActivityDetailsPrice, value: any) => {
          const updatedPrices = [...activityDetailsPrices];
          if(field=="price"){
            updatedPrices[index] = {...updatedPrices[index], price : value };
          }
          if(field=="fromPax"){
            updatedPrices[index] = {...updatedPrices[index], fromPax : value };
          }
          if(field=="toPax"){
            updatedPrices[index] = {...updatedPrices[index], toPax : value };
          }
          setActivityDetailsPrices(updatedPrices);
        };
        /*const handleEditPrice = (id: number, field: keyof IActivityDetailsPrice, value: any) => {
          const updatedPrices = activityDetailsPrices.map(item => {
            if (item.id === id) {
              // Ensure the object strictly matches IActivityDetailsPrice
              return {
                ...item,
                [field]: field === 'price' ? (!isNaN(parseFloat(value)) ? parseFloat(value) : null) : value,
              } as IActivityDetailsPrice; // Explicitly cast the object to match the type
            }
            return item;
          });

          setActivityDetailsPrices(updatedPrices);
        };*/

        const handleDeletePrice = (index: number) => {
          setActivityDetailsPrices(activityDetailsPrices.filter((_, i) => i !== index));
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityDetails.home.createOrEditLabel" data-cy="ActivityDetailsCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityDetails.home.createOrEditLabel">Create or edit a ActivityDetails</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-details-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityDetails.name')}
                id="activity-details-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.activityType')}
                id="activity-details-activityType"
                name="activityType"
                data-cy="activityType"
                type="select"
              >
                {activityTypeValues.map(activityType => (
                  <option value={activityType} key={activityType}>
                    {translate(`activitiesApp.ActivityType.${activityType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityDetails.active')}
                id="activity-details-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.quote')}
                id="activity-details-quote"
                name="quote"
                data-cy="quote"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.summaryDescription')}
                id="activity-details-summaryDescription"
                name="summaryDescription"
                data-cy="summaryDescription"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.detailedDescription')}
                id="activity-details-detailedDescription"
                name="detailedDescription"
                data-cy="detailedDescription"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.startDate')}
                id="activity-details-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.endDate')}
                id="activity-details-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityDetails.activityImageOne')}
                id="activity-details-activityImageOne"
                name="activityImageOne"
                data-cy="activityImageOne"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityDetails.activityImageTwo')}
                id="activity-details-activityImageTwo"
                name="activityImageTwo"
                data-cy="activityImageTwo"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityDetails.activityImageThree')}
                id="activity-details-activityImageThree"
                name="activityImageThree"
                data-cy="activityImageThree"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityDetails.activityImageFour')}
                id="activity-details-activityImageFour"
                name="activityImageFour"
                data-cy="activityImageFour"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityDetails.activityImageFive')}
                id="activity-details-activityImageFive"
                name="activityImageFive"
                data-cy="activityImageFive"
                isImage
                accept="image/*"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.whatsIncluded')}
                id="activity-details-whatsIncluded"
                name="whatsIncluded"
                data-cy="whatsIncluded"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.whatToBring')}
                id="activity-details-whatToBring"
                name="whatToBring"
                data-cy="whatToBring"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.departs')}
                id="activity-details-departs"
                name="departs"
                data-cy="departs"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.restrictions')}
                id="activity-details-restrictions"
                name="restrictions"
                data-cy="restrictions"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.duration')}
                id="activity-details-duration"
                name="duration"
                data-cy="duration"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.vendorLocation')}
                id="activity-details-vendorLocation"
                name="vendorLocation"
                data-cy="vendorLocation"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.childFriendly')}
                id="activity-details-childFriendly"
                name="childFriendly"
                data-cy="childFriendly"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.childFriendlyNote')}
                id="activity-details-childFriendlyNote"
                name="childFriendlyNote"
                data-cy="childFriendlyNote"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.mediaLinks')}
                id="activity-details-mediaLinks"
                name="mediaLinks"
                data-cy="mediaLinks"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.activityId')}
              id="activity-details-activityId"
              name="activityId"
              data-cy="activityId"
              type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetails.timeBaseAvailability')}
              id="activity-details-timeBaseAvailability"
              name="timeBaseAvailability"
              data-cy="timeBaseAvailability"
              check
              type="checkbox"
              />
              <ValidatedField
                id="activity-details-location"
                name="location"
                data-cy="location"
                label={translate('activitiesApp.activityDetails.location')}
                type="select"
              >
                <option value="" key="0" />
                {locations
                  ? locations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.cityName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityDetails.applicationUser')}
                id="activity-details-applicationUser"
                data-cy="applicationUser"
                type="select"
                multiple
                name="applicationUsers"
              >
                <option value="" key="0" />
                {applicationUsers
                  ? applicationUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {activityDetailsPrices &&
              <div className="mb-4">
                <h4>
                  Activity Details Prices {activityDetailsPrices.length}
                </h4>
                {activityDetailsPrices.map((price, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <ValidatedField
                      id={`activityDetailsPrice-price-${index}`}
                      name={`activityDetailsPrices[${index}].price`}
                      label='From Pax'
                      value={price.fromPax || ''}
                      onChange={e => handleEditPrice(index, 'fromPax', Number(e.target.value))}
                      type="number"
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                      className="me-2"
                    />
                    <ValidatedField
                      id={`activityDetailsPrice-price-${index}`}
                      name={`activityDetailsPrices[${index}].price`}
                      label='To Pax'
                      value={price.toPax || ''}
                      onChange={e => handleEditPrice(index, 'toPax', Number(e.target.value))}
                      type="number"
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                      className="me-2"
                    />
                    <ValidatedField
                      id={`activityDetailsPrice-price-${index}`}
                      name={`activityDetailsPrices[${index}].price`}
                      label={translate('activitiesApp.activityDetailsPrice.price')}
                      value={price.price || ''}
                      onChange={e => handleEditPrice(index, 'price', Number(e.target.value))}
                      type="number"
                      step="0.01"
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                      className="me-2"
                    />
                    <Button color="danger" onClick={() => handleDeletePrice(index)} className="btn-sm">
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                ))}
                <Button color="primary" onClick={handleAddPrice}>
                  <FontAwesomeIcon icon="plus" /> <Translate contentKey="entity.action.add">Add</Translate>
                </Button>
              </div>
              }
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-details" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityDetailsUpdate;
