import activity from 'app/entities/activity/activity.reducer';
import activityImage from 'app/entities/activity-image/activity-image.reducer';
import activityHighlight from 'app/entities/activity-highlight/activity-highlight.reducer';
import activityIncludedItem from 'app/entities/activity-included-item/activity-included-item.reducer';
import applicationUser from 'app/entities/application-user/application-user.reducer';
import activityNote from 'app/entities/activity-note/activity-note.reducer';
import activityAvailability from 'app/entities/activity-availability/activity-availability.reducer';
import country from 'app/entities/country/country.reducer';
import location from 'app/entities/location/location.reducer';
import city from 'app/entities/city/city.reducer';
import activityPrice from 'app/entities/activity-price/activity-price.reducer';
import booking from 'app/entities/booking/booking.reducer';
import activityReview from 'app/entities/activity-review/activity-review.reducer';
import bookingPament from 'app/entities/booking-pament/booking-pament.reducer';
import bookingUser from 'app/entities/booking-user/booking-user.reducer';
import bookingPax from 'app/entities/booking-pax/booking-pax.reducer';
import activityTimeAvailability from 'app/entities/activity-time-availability/activity-time-availability.reducer';
import bookingTime from 'app/entities/booking-time/booking-time.reducer';
import weekDayTime from 'app/entities/week-day-time/week-day-time.reducer';
import weekDayTimeAvailability from 'app/entities/week-day-time-availability/week-day-time-availability.reducer';
import activitySchedule from 'app/entities/activity-schedule/activity-schedule.reducer';
import customerReducer from 'app/modules/customer/customerreducer';
import landingReducer from 'app/modules/customer/landingreducer';
import deal from 'app/entities/deal/deal.reducer';
import homeActivityReducer from 'app/modules/customer/homeactivityreducer';
import resultsReducer from 'app/modules/customer/results/resultsreducer';
import itinerary from 'app/entities/itinerary/itinerary.reducer';
import keywordReducer from 'app/modules/customer/results/keywordreducer';
import activityTypeReducer from 'app/modules/customer/results/activitytypereducer';
import emailing from 'app/entities/emailing/emailing.reducer';
import contentImages from 'app/entities/content-images/content-images.reducer';
import criticalLogging from 'app/entities/critical-logging/critical-logging.reducer';
import siteSettings from 'app/entities/site-settings/site-settings.reducer';
import activityFaq from 'app/entities/activity-faq/activity-faq.reducer';
import keyword from 'app/entities/keyword/keyword.reducer';
import diningDetails from 'app/entities/dining-details/dining-details.reducer';
import mealType from 'app/entities/meal-type/meal-type.reducer';
import cusine from 'app/entities/cusine/cusine.reducer';
import activityDetails from 'app/entities/activity-details/activity-details.reducer';
import activityDetailsPrice from 'app/entities/activity-details-price/activity-details-price.reducer';
import activityDetailsDateTimeAvailability from 'app/entities/activity-details-date-time-availability/activity-details-date-time-availability.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  activity,
  activityImage,
  activityHighlight,
  activityIncludedItem,
  applicationUser,
  activityNote,
  activityAvailability,
  country,
  location,
  city,
  activityPrice,
  booking,
  activityReview,
  bookingPament,
  bookingUser,
  bookingPax,
  activityTimeAvailability,
  bookingTime,
  weekDayTime,
  weekDayTimeAvailability,
  activitySchedule,
  customerReducer,
  landingReducer,
  deal,
  homeActivityReducer,
  resultsReducer,
  itinerary,
  keywordReducer,
  activityTypeReducer,
  emailing,
  contentImages,
  criticalLogging,
  siteSettings,
  activityFaq,
  keyword,
  diningDetails,
  mealType,
  cusine,
  activityDetails,
  activityDetailsPrice,
  activityDetailsDateTimeAvailability,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
