import { IActivityDetails } from 'app/shared/model/activity-details.model';

export interface IActivityDetailsPrice {
  id?: number;
  fromPax?: number | null;
  toPax?: number | null;
  price?: number | null;
  activity?: IActivityDetails | null;
}

export const defaultValue: Readonly<IActivityDetailsPrice> = {};
