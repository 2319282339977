import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getActivityDetails } from 'app/entities/activity-details/activity-details.reducer';
import { Hour } from 'app/shared/model/enumerations/hour.model';
import { Minute } from 'app/shared/model/enumerations/minute.model';
import { AMPM } from 'app/shared/model/enumerations/ampm.model';
import { createEntity, getEntity, reset, updateEntity } from './activity-details-date-time-availability.reducer';

export const ActivityDetailsDateTimeAvailabilityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activityDetails = useAppSelector(state => state.activityDetails.entities);
  const activityDetailsDateTimeAvailabilityEntity = useAppSelector(state => state.activityDetailsDateTimeAvailability.entity);
  const loading = useAppSelector(state => state.activityDetailsDateTimeAvailability.loading);
  const updating = useAppSelector(state => state.activityDetailsDateTimeAvailability.updating);
  const updateSuccess = useAppSelector(state => state.activityDetailsDateTimeAvailability.updateSuccess);
  const hourValues = Object.keys(Hour);
  const minuteValues = Object.keys(Minute);
  const aMPMValues = Object.keys(AMPM);

  const handleClose = () => {
    navigate('/activity-details-date-time-availability');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getActivityDetails({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.fromDate = convertDateTimeToServer(values.fromDate);
    values.toDate = convertDateTimeToServer(values.toDate);
    if (values.adultCount !== undefined && typeof values.adultCount !== 'number') {
      values.adultCount = Number(values.adultCount);
    }

    const entity = {
      ...activityDetailsDateTimeAvailabilityEntity,
      ...values,
      activity: activityDetails.find(it => it.id.toString() === values.activity?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDate: displayDefaultDateTime(),
          toDate: displayDefaultDateTime(),
        }
      : {
          hour: 'ONE',
          minute: 'ZERO',
          ampm: 'AM',
          ...activityDetailsDateTimeAvailabilityEntity,
          fromDate: convertDateTimeFromServer(activityDetailsDateTimeAvailabilityEntity.fromDate),
          toDate: convertDateTimeFromServer(activityDetailsDateTimeAvailabilityEntity.toDate),
          activity: activityDetailsDateTimeAvailabilityEntity?.activity?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="activitiesApp.activityDetailsDateTimeAvailability.home.createOrEditLabel"
            data-cy="ActivityDetailsDateTimeAvailabilityCreateUpdateHeading"
          >
            <Translate contentKey="activitiesApp.activityDetailsDateTimeAvailability.home.createOrEditLabel">
              Create or edit a ActivityDetailsDateTimeAvailability
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-details-date-time-availability-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.fromDate')}
                id="activity-details-date-time-availability-fromDate"
                name="fromDate"
                data-cy="fromDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.toDate')}
                id="activity-details-date-time-availability-toDate"
                name="toDate"
                data-cy="toDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.hour')}
                id="activity-details-date-time-availability-hour"
                name="hour"
                data-cy="hour"
                type="select"
              >
                {hourValues.map(hour => (
                  <option value={hour} key={hour}>
                    {translate(`activitiesApp.Hour.${hour}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.minute')}
                id="activity-details-date-time-availability-minute"
                name="minute"
                data-cy="minute"
                type="select"
              >
                {minuteValues.map(minute => (
                  <option value={minute} key={minute}>
                    {translate(`activitiesApp.Minute.${minute}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.ampm')}
                id="activity-details-date-time-availability-ampm"
                name="ampm"
                data-cy="ampm"
                type="select"
              >
                {aMPMValues.map(aMPM => (
                  <option value={aMPM} key={aMPM}>
                    {translate(`activitiesApp.AMPM.${aMPM}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.adultCount')}
                id="activity-details-date-time-availability-adultCount"
                name="adultCount"
                data-cy="adultCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.userName')}
                id="activity-details-date-time-availability-userName"
                name="userName"
                data-cy="userName"
                type="text"
              />
              <ValidatedField
                id="activity-details-date-time-availability-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityDetailsDateTimeAvailability.activity')}
                type="select"
              >
                <option value="" key="0" />
                {activityDetails
                  ? activityDetails.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/activity-details-date-time-availability"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityDetailsDateTimeAvailabilityUpdate;
