import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate, byteSize, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity-details.reducer';

export const ActivityDetailsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityDetailsEntity = useAppSelector(state => state.activityDetails.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityDetailsDetailsHeading">
          <Translate contentKey="activitiesApp.activityDetails.detail.title">ActivityDetails</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="activitiesApp.activityDetails.name">Name</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.name}</dd>
          <dt>
            <span id="activityType">
              <Translate contentKey="activitiesApp.activityDetails.activityType">Activity Type</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.activityType}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="activitiesApp.activityDetails.active">Active</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="quote">
              <Translate contentKey="activitiesApp.activityDetails.quote">Quote</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.quote}</dd>
          <dt>
            <span id="summaryDescription">
              <Translate contentKey="activitiesApp.activityDetails.summaryDescription">Summary Description</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.summaryDescription}</dd>
          <dt>
            <span id="detailedDescription">
              <Translate contentKey="activitiesApp.activityDetails.detailedDescription">Detailed Description</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.detailedDescription}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="activitiesApp.activityDetails.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.startDate ? (
              <TextFormat value={activityDetailsEntity.startDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="activitiesApp.activityDetails.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.endDate ? (
              <TextFormat value={activityDetailsEntity.endDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="activityImageOne">
              <Translate contentKey="activitiesApp.activityDetails.activityImageOne">Activity Image One</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.activityImageOne ? (
              <div>
                {activityDetailsEntity.activityImageOneContentType ? (
                  <a onClick={openFile(activityDetailsEntity.activityImageOneContentType, activityDetailsEntity.activityImageOne)}>
                    <img
                      src={`data:${activityDetailsEntity.activityImageOneContentType};base64,${activityDetailsEntity.activityImageOne}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityDetailsEntity.activityImageOneContentType}, {byteSize(activityDetailsEntity.activityImageOne)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="activityImageTwo">
              <Translate contentKey="activitiesApp.activityDetails.activityImageTwo">Activity Image Two</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.activityImageTwo ? (
              <div>
                {activityDetailsEntity.activityImageTwoContentType ? (
                  <a onClick={openFile(activityDetailsEntity.activityImageTwoContentType, activityDetailsEntity.activityImageTwo)}>
                    <img
                      src={`data:${activityDetailsEntity.activityImageTwoContentType};base64,${activityDetailsEntity.activityImageTwo}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityDetailsEntity.activityImageTwoContentType}, {byteSize(activityDetailsEntity.activityImageTwo)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="activityImageThree">
              <Translate contentKey="activitiesApp.activityDetails.activityImageThree">Activity Image Three</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.activityImageThree ? (
              <div>
                {activityDetailsEntity.activityImageThreeContentType ? (
                  <a onClick={openFile(activityDetailsEntity.activityImageThreeContentType, activityDetailsEntity.activityImageThree)}>
                    <img
                      src={`data:${activityDetailsEntity.activityImageThreeContentType};base64,${activityDetailsEntity.activityImageThree}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityDetailsEntity.activityImageThreeContentType}, {byteSize(activityDetailsEntity.activityImageThree)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="activityImageFour">
              <Translate contentKey="activitiesApp.activityDetails.activityImageFour">Activity Image Four</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.activityImageFour ? (
              <div>
                {activityDetailsEntity.activityImageFourContentType ? (
                  <a onClick={openFile(activityDetailsEntity.activityImageFourContentType, activityDetailsEntity.activityImageFour)}>
                    <img
                      src={`data:${activityDetailsEntity.activityImageFourContentType};base64,${activityDetailsEntity.activityImageFour}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityDetailsEntity.activityImageFourContentType}, {byteSize(activityDetailsEntity.activityImageFour)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="activityImageFive">
              <Translate contentKey="activitiesApp.activityDetails.activityImageFive">Activity Image Five</Translate>
            </span>
          </dt>
          <dd>
            {activityDetailsEntity.activityImageFive ? (
              <div>
                {activityDetailsEntity.activityImageFiveContentType ? (
                  <a onClick={openFile(activityDetailsEntity.activityImageFiveContentType, activityDetailsEntity.activityImageFive)}>
                    <img
                      src={`data:${activityDetailsEntity.activityImageFiveContentType};base64,${activityDetailsEntity.activityImageFive}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityDetailsEntity.activityImageFiveContentType}, {byteSize(activityDetailsEntity.activityImageFive)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="whatsIncluded">
              <Translate contentKey="activitiesApp.activityDetails.whatsIncluded">Whats Included</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.whatsIncluded}</dd>
          <dt>
            <span id="whatToBring">
              <Translate contentKey="activitiesApp.activityDetails.whatToBring">What To Bring</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.whatToBring}</dd>
          <dt>
            <span id="departs">
              <Translate contentKey="activitiesApp.activityDetails.departs">Departs</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.departs}</dd>
          <dt>
            <span id="restrictions">
              <Translate contentKey="activitiesApp.activityDetails.restrictions">Restrictions</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.restrictions}</dd>
          <dt>
            <span id="duration">
              <Translate contentKey="activitiesApp.activityDetails.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.duration}</dd>
          <dt>
            <span id="vendorLocation">
              <Translate contentKey="activitiesApp.activityDetails.vendorLocation">Vendor Location</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.vendorLocation}</dd>
          <dt>
            <span id="childFriendly">
              <Translate contentKey="activitiesApp.activityDetails.childFriendly">Child Friendly</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.childFriendly ? 'true' : 'false'}</dd>
          <dt>
            <span id="childFriendlyNote">
              <Translate contentKey="activitiesApp.activityDetails.childFriendlyNote">Child Friendly Note</Translate>
            </span>
          </dt>
          <dd>{activityDetailsEntity.childFriendlyNote}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activityDetails.location">Location</Translate>
          </dt>
          <dd>{activityDetailsEntity.location ? activityDetailsEntity.location.cityName : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activityDetails.applicationUser">Application User</Translate>
          </dt>
          <dd>
            {activityDetailsEntity.applicationUsers
              ? activityDetailsEntity.applicationUsers.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {activityDetailsEntity.applicationUsers && i === activityDetailsEntity.applicationUsers.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/activity-details" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity-details/${activityDetailsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityDetailsDetail;
