import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { TextFormat, Translate, byteSize, getSortState, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
//import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './activity-details.reducer';

export const ActivityDetails = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overridePaginationStateWithQueryParams(getSortState(pageLocation, parseInt('id')), pageLocation.search));

  const activityDetailsList = useAppSelector(state => state.activityDetails.entities);
  const loading = useAppSelector(state => state.activityDetails.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="activity-details-heading" data-cy="ActivityDetailsHeading">
        <Translate contentKey="activitiesApp.activityDetails.home.title">Activity Details</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="activitiesApp.activityDetails.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/activity-details/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="activitiesApp.activityDetails.home.createLabel">Create new Activity Details</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {activityDetailsList && activityDetailsList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="activitiesApp.activityDetails.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="activitiesApp.activityDetails.name">Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                </th>
                <th className="hand" onClick={sort('activityType')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityType">Activity Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityType')} />
                </th>
                <th className="hand" onClick={sort('active')}>
                  <Translate contentKey="activitiesApp.activityDetails.active">Active</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('active')} />
                </th>
                <th className="hand" onClick={sort('quote')}>
                  <Translate contentKey="activitiesApp.activityDetails.quote">Quote</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('quote')} />
                </th>
                <th className="hand" onClick={sort('summaryDescription')}>
                  <Translate contentKey="activitiesApp.activityDetails.summaryDescription">Summary Description</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('summaryDescription')} />
                </th>
                <th className="hand" onClick={sort('detailedDescription')}>
                  <Translate contentKey="activitiesApp.activityDetails.detailedDescription">Detailed Description</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('detailedDescription')} />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="activitiesApp.activityDetails.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('endDate')}>
                  <Translate contentKey="activitiesApp.activityDetails.endDate">End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endDate')} />
                </th>
                <th className="hand" onClick={sort('activityImageOne')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityImageOne">Activity Image One</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityImageOne')} />
                </th>
                <th className="hand" onClick={sort('activityImageTwo')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityImageTwo">Activity Image Two</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityImageTwo')} />
                </th>
                <th className="hand" onClick={sort('activityImageThree')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityImageThree">Activity Image Three</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityImageThree')} />
                </th>
                <th className="hand" onClick={sort('activityImageFour')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityImageFour">Activity Image Four</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityImageFour')} />
                </th>
                <th className="hand" onClick={sort('activityImageFive')}>
                  <Translate contentKey="activitiesApp.activityDetails.activityImageFive">Activity Image Five</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityImageFive')} />
                </th>
                <th className="hand" onClick={sort('whatsIncluded')}>
                  <Translate contentKey="activitiesApp.activityDetails.whatsIncluded">Whats Included</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('whatsIncluded')} />
                </th>
                <th className="hand" onClick={sort('whatToBring')}>
                  <Translate contentKey="activitiesApp.activityDetails.whatToBring">What To Bring</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('whatToBring')} />
                </th>
                <th className="hand" onClick={sort('departs')}>
                  <Translate contentKey="activitiesApp.activityDetails.departs">Departs</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('departs')} />
                </th>
                <th className="hand" onClick={sort('restrictions')}>
                  <Translate contentKey="activitiesApp.activityDetails.restrictions">Restrictions</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('restrictions')} />
                </th>
                <th className="hand" onClick={sort('duration')}>
                  <Translate contentKey="activitiesApp.activityDetails.duration">Duration</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('duration')} />
                </th>
                <th className="hand" onClick={sort('vendorLocation')}>
                  <Translate contentKey="activitiesApp.activityDetails.vendorLocation">Vendor Location</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('vendorLocation')} />
                </th>
                <th className="hand" onClick={sort('childFriendly')}>
                  <Translate contentKey="activitiesApp.activityDetails.childFriendly">Child Friendly</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('childFriendly')} />
                </th>
                <th className="hand" onClick={sort('childFriendlyNote')}>
                  <Translate contentKey="activitiesApp.activityDetails.childFriendlyNote">Child Friendly Note</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('childFriendlyNote')} />
                </th>
                <th>
                  <Translate contentKey="activitiesApp.activityDetails.location">Location</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="activitiesApp.activityDetails.applicationUser">Application User</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activityDetailsList.map((activityDetails, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/activity-details/${activityDetails.id}`} color="link" size="sm">
                      {activityDetails.id}
                    </Button>
                  </td>
                  <td>{activityDetails.name}</td>
                  <td>
                    <Translate contentKey={`activitiesApp.ActivityType.${activityDetails.activityType}`} />
                  </td>
                  <td>{activityDetails.active ? 'true' : 'false'}</td>
                  <td>{activityDetails.quote}</td>
                  <td>{activityDetails.summaryDescription}</td>
                  <td>{activityDetails.detailedDescription}</td>
                  <td>
                    {activityDetails.startDate ? (
                      <TextFormat type="date" value={activityDetails.startDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {activityDetails.endDate ? <TextFormat type="date" value={activityDetails.endDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {activityDetails.activityImageOne ? (
                      <div>
                        {activityDetails.activityImageOneContentType ? (
                          <a onClick={openFile(activityDetails.activityImageOneContentType, activityDetails.activityImageOne)}>
                            <img
                              src={`data:${activityDetails.activityImageOneContentType};base64,${activityDetails.activityImageOne}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityDetails.activityImageOneContentType}, {byteSize(activityDetails.activityImageOne)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {activityDetails.activityImageTwo ? (
                      <div>
                        {activityDetails.activityImageTwoContentType ? (
                          <a onClick={openFile(activityDetails.activityImageTwoContentType, activityDetails.activityImageTwo)}>
                            <img
                              src={`data:${activityDetails.activityImageTwoContentType};base64,${activityDetails.activityImageTwo}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityDetails.activityImageTwoContentType}, {byteSize(activityDetails.activityImageTwo)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {activityDetails.activityImageThree ? (
                      <div>
                        {activityDetails.activityImageThreeContentType ? (
                          <a onClick={openFile(activityDetails.activityImageThreeContentType, activityDetails.activityImageThree)}>
                            <img
                              src={`data:${activityDetails.activityImageThreeContentType};base64,${activityDetails.activityImageThree}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityDetails.activityImageThreeContentType}, {byteSize(activityDetails.activityImageThree)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {activityDetails.activityImageFour ? (
                      <div>
                        {activityDetails.activityImageFourContentType ? (
                          <a onClick={openFile(activityDetails.activityImageFourContentType, activityDetails.activityImageFour)}>
                            <img
                              src={`data:${activityDetails.activityImageFourContentType};base64,${activityDetails.activityImageFour}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityDetails.activityImageFourContentType}, {byteSize(activityDetails.activityImageFour)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {activityDetails.activityImageFive ? (
                      <div>
                        {activityDetails.activityImageFiveContentType ? (
                          <a onClick={openFile(activityDetails.activityImageFiveContentType, activityDetails.activityImageFive)}>
                            <img
                              src={`data:${activityDetails.activityImageFiveContentType};base64,${activityDetails.activityImageFive}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityDetails.activityImageFiveContentType}, {byteSize(activityDetails.activityImageFive)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>{activityDetails.whatsIncluded}</td>
                  <td>{activityDetails.whatToBring}</td>
                  <td>{activityDetails.departs}</td>
                  <td>{activityDetails.restrictions}</td>
                  <td>{activityDetails.duration}</td>
                  <td>{activityDetails.vendorLocation}</td>
                  <td>{activityDetails.childFriendly ? 'true' : 'false'}</td>
                  <td>{activityDetails.childFriendlyNote}</td>
                  <td>
                    {activityDetails.location ? (
                      <Link to={`/location/${activityDetails.location.id}`}>{activityDetails.location.cityName}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {activityDetails.applicationUsers
                      ? activityDetails.applicationUsers.map((val, j) => (
                          <span key={j}>
                            <Link to={`/application-user/${val.id}`}>{val.id}</Link>
                            {j === activityDetails.applicationUsers.length - 1 ? '' : ', '}
                          </span>
                        ))
                      : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/activity-details/${activityDetails.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-details/${activityDetails.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/activity-details/${activityDetails.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="activitiesApp.activityDetails.home.notFound">No Activity Details found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;
