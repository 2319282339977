import dayjs from 'dayjs';
import { IActivityDetails } from 'app/shared/model/activity-details.model';
import { Hour } from 'app/shared/model/enumerations/hour.model';
import { Minute } from 'app/shared/model/enumerations/minute.model';
import { AMPM } from 'app/shared/model/enumerations/ampm.model';

export interface IActivityDetailsDateTimeAvailability {
  id?: number;
  fromDate?: dayjs.Dayjs | null;
  toDate?: dayjs.Dayjs | null;
  hour?: keyof typeof Hour | null;
  minute?: keyof typeof Minute | null;
  ampm?: keyof typeof AMPM | null;
  adultCount?: number | null;
  userName?: string | null;
  activity?: IActivityDetails | null;
}

export const defaultValue: Readonly<IActivityDetailsDateTimeAvailability> = {};
