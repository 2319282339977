import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityDetailsPrice from './activity-details-price';
import ActivityDetailsPriceDetail from './activity-details-price-detail';
import ActivityDetailsPriceUpdate from './activity-details-price-update';
import ActivityDetailsPriceDeleteDialog from './activity-details-price-delete-dialog';

const ActivityDetailsPriceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityDetailsPrice />} />
    <Route path="new" element={<ActivityDetailsPriceUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityDetailsPriceDetail />} />
      <Route path="edit" element={<ActivityDetailsPriceUpdate />} />
      <Route path="delete" element={<ActivityDetailsPriceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityDetailsPriceRoutes;
